<template>
  <div class="position-card">
    <div class="position-card__body grid p-25">
      <span class="position-card__count">
        Позиция №{{ count }}
      </span>

      <h4 class="position-card__subtitle">
        {{ item.name }}
      </h4>
      <p>
        {{item.table_characteristic}}
      </p>
      <div
        class="position-card__list grid grid--gap-20"
      >
        <div
          v-if="item.parameters.length"
        >
          <VTextBlock
            v-for="parameter in filterParameters"
            :key="parameter"
            :subtitle="parameter.full_name"
            :description="parameter.options.find(el => el.id === parameter.is_selected)?.name"
          />
        </div>

        <VTextBlock
          v-if="permissionReadParticipantNote && item.participant_note"
          subtitle="Примечание участника"
          :description="item.participant_note"
        />
      </div>
    </div>

    <div class="position-card__footer p-25 bg--yellow">
      <VTextBlock
        subtitle="Цена"
        :description="formatPrice(item.price)"
        font-size-description="24"
      />
    </div>
  </div>
</template>

<script>
import VTextBlock from '@/components/textblock/VTextBlock'
import { formatPrice } from '@/utils/utils'
import { usePermissionReadOfferInfo } from '@/use/usePermission'
import { computed } from 'vue'
import { checkParametersOnlySelected } from '@/utils/lot/function.lot'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const permissionReadParticipantNote = usePermissionReadOfferInfo()

    const filterParameters = computed(() => {
      if (!props.item.parameters.length) return
      return checkParametersOnlySelected(props.item.parameters)
    })

    return {
      filterParameters,
      permissionReadParticipantNote,
      formatPrice
    }
  },
  components: {
    VTextBlock
  }
}
</script>
